<template>
  <div class="flex mb-15 flex-align-c">
    <span class="flex2 title">
      音视频（共{{pagination.total}}条）<span class="font-12">已选{{checkList.length}}</span>
    </span>
    <a-input-search
      class="flex1"
      :value="value"
      :placeholder="placeholder"
      enter-button
      @change="onChange"
      @search="pressEnter"
      :allowClear="allowClear"
    />
  </div>
  <a-table size="small" :dataSource="dataSource" :columns="columns" :rowSelection="rowSelection" rowKey="id" :pagination="pagination" @change="changePage">
    <template #name="{record}">
      <div class="flex flex-align-c">
        <a-image
          v-if="record.type === 'm4'"
          :preview="false"
          :width="160"
          :height="90"
          :src="OSSURL+'/'+record.file_path+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_160,h_90'"
        />
        <div class="video-box" v-if="record.type === 'm3'">
          <a-image
            :preview="false"
            :width="55"
            :height="64"
            :src="require('@/assets/images/icon_audio_max.png')"
          />
        </div>
        <span class="pl-10">{{record.file_name}}</span>
      </div>
    </template>
    <template #action1="{record}">
      <PlayCircleOutlined class="icon-play" @click="hanldePlay(record)"/>
    </template>
  </a-table>
  <!-- 视频预览 -->
  <videoDialog v-model:href="videoUrl" v-model:visible="visible"></videoDialog>
  <!-- 音频预览 -->
  <radioDialog v-model:href="videoUrl" v-model:visible="visibleRadio"></radioDialog>
</template>

<script>
import { defineComponent, reactive, ref, watch } from 'vue'
import { PlayCircleOutlined } from '@ant-design/icons-vue'
import videoDialog from '@/components/videoDialog.vue'
import radioDialog from '@/components/radioDialog.vue'
import { OSSURL } from '@/hooks/ali-oss'
export default defineComponent({
  components: {
    PlayCircleOutlined,
    videoDialog,
    radioDialog
  },
  props: {
    value: {}, // 搜索名字
    placeholder: {
      type: String,
      default: '请输入要搜索的文件名'
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    dataSource: {},
    pagination: {}
  },
  emits: ['change', 'pressEnter'],
  setup (props, ctx) {
    const videoUrl = ref()
    const visible = ref(false)
    const visibleRadio = ref(false)
    const columns = [{
      title: '名称',
      dataIndex: 'file_name',
      slots: { customRender: 'file_name' },
      key: 'file_name'
    },
    {
      title: '操作',
      key: 'action',
      slots: { customRender: 'action1' },
      width: 50
    }]
    const onChange = (e) => {
      ctx.emit('update:value', e.target.value)
    }
    const hanldePlay = (record) => {
      if (record.type === 'm3') {
        visibleRadio.value = true
      } else {
        visible.value = true
      }
      videoUrl.value = OSSURL + '/' + record.file_path
    }
    // 记录已选列表
    const checkList = ref([])
    const rowSelection = reactive({
      selectedRowKeys: [], // 指定选中项的 key 数组
      onChange: (selectedRowKeys, selectedRows) => {
        rowSelection.selectedRowKeys = selectedRowKeys
        if (checkList.value.length === 0) {
          checkList.value = selectedRows
        } else {
          checkList.value = checkList.value.concat(selectedRows.filter((item) => !checkList.value.find((items) => items.id === item.id)))
        }
        checkList.value = checkList.value.filter((item) => selectedRowKeys.indexOf(item.id) !== -1)
      }
    })
    const pressEnter = () => {
      ctx.emit('pressEnter')
    }
    const changePage = ({ current }) => {
      ctx.emit('change', current)
    }
    return {
      columns,
      hanldePlay,
      videoUrl,
      visible,
      rowSelection,
      onChange,
      visibleRadio,
      OSSURL,
      checkList,
      pressEnter,
      changePage
    }
  }
})
</script>

<style lang="scss" scoped>
.flex1{
  flex: 1;
}
.flex2{
  flex: 2;
}
.title{
  font-size: 15px;
  color: #000;
}
.icon-play{
  font-size: 20px;
  color: #999;
}
.video-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 90px;
  background: #FAFAFA;
}
.font-12{
  font-size: 12px;
  color: #155FEF;
}
</style>
