<template>
  <div class="flex mb-15">
    <span class="flex2 title flex-align-c">
      图片（共{{pagination.total}}条）<span class="font-12">已选{{checkList.length}}</span>
    </span>
    <a-input-search
      class="flex1"
      :value="value"
      :placeholder="placeholder"
      enter-button
      @change="onChange"
      @search="pressEnter"
      :allowClear="allowClear"
    />
  </div>
  <a-row :gutter="16">
    <a-col class="listItem" :span="4" v-for="(item, index) in dataSource" :key="index">
      <div class="imgbox">
        <a-checkbox :checked="item.checked" class="checkBox" @change="changeCheck($event, index, item)"></a-checkbox>
        <a-image
          :src="OSSURL+'/'+item.file_path"
        />
      </div>
      <div class="text">{{item.file_name}}</div>
    </a-col>
  </a-row>
  <a-pagination class="txt-r" size="small" :current="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total" @change="changePage"/>
</template>

<script>
import { computed, defineComponent, ref, toRefs, reactive, watch } from 'vue'
import { OSSURL } from '@/hooks/ali-oss'

export default defineComponent({
  props: {
    value: {}, // 搜索名字
    placeholder: {
      type: String,
      default: '请输入图片名'
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    dataSource: {},
    pagination: {}
  },
  emits: ['change', 'changeCheck', 'pressEnter'],
  setup (props, ctx) {
    const checkList = ref([])
    const rowSelection = reactive({
      selectedRowKeys: [] // 指定选中项的 key 数组
    })
    const columns = [{
      title: '名称',
      dataIndex: 'file_name',
      key: 'file_name'
    },
    {
      title: '缩略图',
      dataIndex: 'address',
      key: 'address'
    }]
    const changePage = (page) => {
      ctx.emit('change', page)
    }
    const onChange = (e) => {
      ctx.emit('update:value', e.target.value)
    }
    const changeCheck = (e, index, item) => {
      console.log('我执行的次数', e.target.checked, index)
      if (e.target.checked && !rowSelection.selectedRowKeys.includes(item.id)) {
        rowSelection.selectedRowKeys.push(item.id)
        checkList.value.push(item)
      } else {
        rowSelection.selectedRowKeys.splice(rowSelection.selectedRowKeys.findIndex(items => items === item.id), 1)
        checkList.value.splice(checkList.value.findIndex(items => items.id === item.id), 1)
      }
      ctx.emit('changeCheck', e.target.checked, index)
    }
    const pressEnter = () => {
      ctx.emit('pressEnter')
    }
    return {
      columns,
      changePage,
      OSSURL,
      onChange,
      changeCheck,
      pressEnter,
      checkList,
      rowSelection
    }
  }
})
</script>

<style lang="scss" scoped>
.flex1{
  flex: 1;
}
.flex2{
  flex: 2;
}
.title{
  font-size: 15px;
  color: #000;
}
.listItem {
  margin-bottom: 10px;
}
.imgbox{
  width:100%;
  position: relative;
  ::v-deep .ant-image-img{
    width:100%;
    height:120px !important;
  }
}
.checkBox{
  position: absolute;
  top: 0;
  left:2px;
  z-index: 100;
}
.text{
  text-align: center;
  width: 100%;
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.txt-r{
  text-align: right;
}
::v-deep .ant-pagination-item{
  border: none;
}
</style>
